import React, { useState } from "react";
import GamesDisplay from "../components/games/GameDisplay";
import NavBar from "../components/misc/NavBar";
import AlertSlip from "../components/misc/AlertSlip";
import Login from "../components/misc/LoginCard";
import Header from "../components/misc/Header";
import GamesSearch from "../components/games/GamesSearch";
import styles from "./CSS/MainSportPage.module.css";

function NbaPage() {
  const [showLoginCard, setShowLoginCard] = useState(false);
  const [filterCriteria, setFilteraCriteria] = useState({});

  const handleFilterChange = (criteriaName, value) => {
    setFilteraCriteria((prevCriteria) => ({
      ...prevCriteria,
      [criteriaName]: value,
    }));
  };

  const toggleLoginCard = () => {
    setShowLoginCard((prevState) => !prevState);
  };

  return (
    <div>
      <div className={styles.headerContainer}>
        <Header league="NBA" />
      </div>
      <div className={styles.search}>
        <GamesSearch
        onFilterChange={handleFilterChange}
        />
      </div>
      <div className={styles.pageLayout}>
        <div className={styles.navBarContainer}>
          <NavBar onSignInClick={toggleLoginCard} />
          {showLoginCard && <Login onHide={toggleLoginCard} />}
        </div>
        <div className={styles.mainContent}>
          <div className={styles.gamesAndAlerts}>
            <div className={styles.gamesContainer}>
            <GamesDisplay league="NBA" filterCriteria={filterCriteria} />
            </div>
            <div className={styles.alertSlipContainerProps}>
              <AlertSlip />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NbaPage;
