import React, { useState } from "react";
import CurrentAlertCard from "./CurrentAlertCard";
import styles from "../CSS/ParlayCard.module.css";

const ParlayCard = ({ parlayAlerts, parlayName, parlayId, onNameChange, onDeleteParlay }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(parlayName);

  const toggleExpand = () => {
    if (!isEditing) {
      setIsExpanded(!isExpanded);
    }
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleNameChange = (e) => {
    setNewName(e.target.value);
  };

  const handleSaveClick = async (e) => {
    e.stopPropagation(); 
    await onNameChange(parlayId, newName);
    setIsEditing(false);
  };

  const greenCount = parlayAlerts.filter(alert => alert.is_green).length;
  const allGreen = greenCount === parlayAlerts.length;
  const cardStyle = {
    backgroundColor: allGreen ? 'rgba(0, 255, 0, 0.2)' : (isExpanded ? '#2a3947' : undefined),
  };

  return (
    <div className={styles.parlayCard} style={cardStyle}>
      <div className={styles.parlayCardHeader} onClick={toggleExpand}>
      {
        isEditing ? (
          <>
          <div className={styles.inputEditDiv}>
            Parlay: 
            <input
              type="text"
              className={styles.inputEdit} 
              value={newName}
              onChange={handleNameChange}
            />
            </div>
            <div className={styles.saveButtonDiv}>
            <button
              className={styles.saveButton} 
              onClick={handleSaveClick}
            >
              Save Name
            </button>
            </div>
          </>
        ) : (
          <>
          <div className={styles.parlayName}>
            <span style={{
              display: 'inline-block',
              width: '250px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
          Parlay: {parlayName} <span className={styles.clickExpandText}>(click to expand)</span>
        </span>
        </div>
        <div className={styles.winningBets}>
        <span>In the green: {greenCount}/{parlayAlerts.length}</span>
        </div>
        {!isExpanded &&
        <div className={styles.buttons}>
          
          <span><button className={styles.button_8} onClick={handleEditClick}>Change Name</button></span>
          <span>
            <button className={styles.button_8} onClick={(e) => {
              e.stopPropagation(); 
              onDeleteParlay(parlayId);
            }}>
              Delete Parlay
            </button>
          </span>
          
        </div>
}
          </>
        )} 
      </div>
      {isExpanded && (
        <div className={styles.parlayCardContent}>
          {parlayAlerts.map((alert, index) => (
            <CurrentAlertCard
              gameID={alert.game_id}
              name={alert.name}
              league={alert.league}
              bet_type={alert.bet_type}
              user_odds={alert.user_odds}
              current_odds={alert.current_odds}
              h_a={alert.h_a}
              is_green={alert.is_green}
              expandedStyle={true}
              game_status={alert.game_status}
              game_time={alert.game_time}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ParlayCard;