import React from "react";
import { Link } from "react-router-dom";
import TeamCard from "./TeamCard";
import styles from "../CSS/GameCard.module.css";

const GameCard = ({ game }) => {

  return (
    <div className={styles.gameCard}>
      <div className={styles.teamCardsContainer}>
        <TeamCard
          gameID={game.id}
          league={game.league}
          h_a="a"
          teamName={game.away_team}
          opponent={game.home_team}
          teamScore={game.away_score}
          pregameMl={game.away_ml}
          pregameSpread={game.away_spread}
          pregameTotal={game.game_total}
          currentML={game.away_ml_live}
          currentSpread={game.away_spread_live}
          currentTotal={game.game_total_live}
          category="team"
        />
        <TeamCard
          gameID={game.id}
          league={game.league}
          h_a="h"
          teamName={game.home_team}
          opponent={game.away_team}
          teamScore={game.home_score}
          pregameMl={game.home_ml}
          pregameSpread={game.home_spread}
          pregameTotal={game.game_total}
          currentML={game.home_ml_live}
          currentSpread={game.home_spread_live}
          currentTotal={game.game_total_live}
          category="team"
        />
      </div>
      <div className={styles.gameFooter}>
        <p className={styles.gameTime}>
          {game.game_status === "Final"
            ? "Final"
            : game.game_status === "In Progress"
            ? "In Progress"
            : `Start: ${game.game_time}`}
        </p>
        {(game.league.toLowerCase() === "nba" || game.league.toLowerCase() ===  "mlb") && (
          <Link
          to={{
            pathname: `/game`,
            search: `?league=${game.league}&gameid=${game.id}&prop_name=game_bets`,
          }}
          className={styles.playerProps}
        >
          More Wagers &#160;&#160;&gt;
        </Link>
        )}
      </div>
    </div>
  );
};

export default GameCard;
