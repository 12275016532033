import React, { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { useAuth } from "./AuthContext"; 

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [nbaGameObjects, setNbaGameObjects] = useState({});
  const [nbaPropsObjects, setNbaPropsObjects] = useState({});
  const [ncaa_mbGameObjects, setNcaaMbGameObjects] = useState({});
  const [mlbGameObjects, setMlbGameObjects] = useState({});
  const [mlbPropsObjects, setMlbPropsObjects] = useState({});
  const { isLoggedIn } = useAuth(); 

  
  useEffect(() => {
    const sportsbook = localStorage.getItem("sportsbook") || "fanduel";
    const newSocket = io("https://livebetalert-297138c52c76.herokuapp.com", {
    query: {
        token: isLoggedIn ? localStorage.getItem("token") : "",
        sportsbook: sportsbook,
      },
    }); 

    newSocket.on("nba_games_objects", (gameObjects) => {
      setNbaGameObjects(gameObjects);
    });

    newSocket.on("nba_props_objects", (propsData) => {
      setNbaPropsObjects(propsData);
    });

    newSocket.on("ncaa_mb_games_objects", (gameObjects) => {
      setNcaaMbGameObjects(gameObjects);
    });

    newSocket.on("mlb_games_objects", (gameObjects) => {
      setMlbGameObjects(gameObjects);
    });

    newSocket.on("mlb_props_objects", (propsData) => {
      setMlbPropsObjects(propsData);
    });

    setSocket(newSocket);

    return () => {
      if (newSocket) {
        newSocket.off("nba_games_objects");
        newSocket.off("nba_props_objects");
        newSocket.off("ncaa_mb_games_objects");
        newSocket.off("mlb_games_objects")
        newSocket.off("mlb_props_objects")
        newSocket.close();
      }
    };
  }, [isLoggedIn]); 

  const contextValue = {
    socket,
    nbaGameObjects,
    nbaPropsObjects,
    ncaa_mbGameObjects,
    mlbGameObjects, 
    mlbPropsObjects
  };

  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  );
};

