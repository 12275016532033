// TeamCard.js
import React, { useState } from "react";
import { generateTeamLogoUrl } from "../../utils/helpers";
import { useAlertSlip } from "../../contexts/AlertSlipContext";
import styles from "../CSS/TeamCard.module.css";

const TeamCard = ({
  gameID,
  league,
  h_a,
  teamName,
  opponent,
  teamScore,
  pregameMl,
  pregameSpread,
  pregameTotal,
  currentML,
  currentSpread,
  currentTotal,
  category,
}) => {
  const [logoExists, setLogoExists] = useState(true);
  const { addAlertSlip } = useAlertSlip();
  const teamLogoUrl = generateTeamLogoUrl(teamName, league.toLowerCase());
  const handleLogoError = () => {
    setLogoExists(false);
  };

  const handleAlertClick = (betType, currentOdds) => {

    const adjustedBetType =
    betType === "Total" ? `Total - ${h_a === "h" ? "under" : "over"}` : betType;
    const oddsThreshold = "";

    const alertData = {
      gameID,
      teamName,
      betType: adjustedBetType,
      opponent,
      h_a,
      league,
      category,
      oddsThreshold,
      currentOdds
    };
    addAlertSlip(alertData);
  };

  return (
    <div className={styles.teamCard}>
      <div className={styles.teamInfo}>
        <div className={styles.logo}>
          {logoExists && (
            <img
              src={teamLogoUrl}
              alt={`${teamName} logo`}
              onError={handleLogoError}
              className={styles.logoImage}
            />
          )}
        </div>
        <div className={styles.teamName}>
          {h_a === "h" ? "(H) " : "(A) "}
          {teamName}
        </div>
      </div>
      <div className={styles.teamScore}>
        {teamScore || teamScore === 0 ? teamScore : ""}
      </div>
      <div className={styles.pregameOdds}>
        {pregameMl}, {pregameSpread[0]}, {pregameTotal}
      </div>
      <div
        className={`${styles.currentOdds} ${styles.clickableDiv}`}
        onClick={() => handleAlertClick("ML", currentML)}
      >
        {currentML}
      </div>
      <div
        className={`${styles.currentOdds} ${styles.clickableDiv}`}
        onClick={() => handleAlertClick("Spread", currentSpread)}
      >
        {league.toLowerCase() === 'mlb' ? (
            <span>
              {currentSpread[0]} <span style={{ fontSize: '11px' }}>{currentSpread[1]}</span>
            </span>
          ) : (
            currentSpread[0]
          )}
      </div>
      <div
        className={`${styles.currentOdds} ${styles.clickableDiv}`}
        onClick={() => handleAlertClick("Total", currentTotal)}
      >
        { currentTotal !== '-' ? `${h_a === "h" ? "U " : "O "} ${currentTotal}` : '-'}
      </div>
    </div>
  );
};

export default TeamCard;
