export const generateTeamLogoUrl = (teamName, league) => {
  const tempLeague = league === "ncaa_mb" ? "ncaa" : league.toLowerCase();
  const logoUrl = `https://livebetalert-297138c52c76.herokuapp.com/static/logos/${tempLeague}/${tempLeague}_${teamName
    .replace(/\s+/g, "_")
    .toLowerCase()}.png`;
  return logoUrl;
};

export const generateLeagueLogoUrl = (league) => {
  const tempLeague = league === "ncaa_mb" ? "ncaa" : league.toLowerCase();
  const logoUrl = `https://livebetalert-297138c52c76.herokuapp.com/static/logos/${tempLeague}/${tempLeague}.png`;
  return logoUrl;
};

export const formatPropName = (propName) => {
  return propName
    .split("_") 
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" "); 
};

export const convertTimeToMinutes = (timeStr) => {
  const [time, modifier] = timeStr.split(" ");
  let [hours, minutes] = time.split(":").map(Number);
  if (hours === 12) {
    hours = 0; 
  }
  if (modifier === "PM") {
    hours += 12;
  }
  return hours * 60 + minutes;
};

export const sortGames = (gamesArray) => {
  return gamesArray.sort((a, b) => {
    const timeDifference =
      convertTimeToMinutes(a.game_time) - convertTimeToMinutes(b.game_time);
    if (timeDifference === 0) {
      return a.home_team.localeCompare(b.home_team);
    }
    return timeDifference;
  });
};

export const sortByName = (a, b) => {
  if (a[0] < b[0]) {
    return -1;
  }
  if (a[0] > b[0]) {
    return 1;
  }
  return 0;
};
