import { useState } from "react";
import styles from "../CSS/AlertsFilter.module.css";

const AlertsFilter = ({ onFilterChange, onResetFilters }) => {
  const [isGreen, setIsGreen] = useState(false);

  const handleFilterChange = (e) => {
    onFilterChange(e.target.name, e.target.value);
  };

  const handleIsGreenChange = (e) => {
    const isChecked = e.target.checked;
    setIsGreen(isChecked);
    onFilterChange('is_green', isChecked);
  };


  return (
    <div className={styles.mainContent}>
      <div className={styles.filterHeader}>
        Alerts Filter
      </div>
        <div className={styles.filterContent}>
          <div className={styles.filterSelection}>
              <span className={styles.filterDescriptor}>League:</span>
              <select name="league" onChange={handleFilterChange}>
                <option value="">All Leagues</option>
                <option value="nba">NBA</option>
                <option value="ncaa_mb">NCAA Mens</option>
              </select>

          </div>
          <div className={styles.filterSelection}>
            <span className={styles.filterDescriptor}>Bet Type:</span>
            <select name="bet_type" onChange={handleFilterChange}>
              <option value="">All Bets</option>
              <option value="ML">Moneylines</option>
              <option value="Spread">Spreads</option>
              <option value="Total">Totals</option>
              <option value="player">Player Props</option>
            </select>
          </div>
          <div className={styles.filterSelection}>
            <span className={styles.filterDescriptor}>Search:</span>
            <form>
              <input
                type="text"
                name="search"
                className={styles.searchBarElement}
                onChange={handleFilterChange}
                placeholder="Search by name.."
                style={{
                  width: 150 + "px",
                }}
              />
            </form>
          </div>
          <div className={styles.byIsGreen}>
            <span className={styles.filterDescriptor}>In the green:</span>
            <input
              type="checkbox"
              name="is_green"
              checked={isGreen}
              onChange={handleIsGreenChange}
            />
          </div>
          <button onClick={onResetFilters} className={styles.button_8}>
            Reset Filters
          </button>
        </div>
    </div>
  );
};

export default AlertsFilter;
