import React from "react";
import {
  generateLeagueLogoUrl,
  generateTeamLogoUrl,
} from "../../utils/helpers";
import styles from "../CSS/CurrentAlertCard.module.css";

const CurrentAlertCard = ({
  alertID,
  gameID,
  name,
  league,
  bet_type,
  user_odds,
  current_odds,
  h_a,
  onDelete,
  is_green, 
  expandedStyle = false,
  game_status,
  game_time
}) => {
  const isTotalBet = bet_type.startsWith("Total");
  const isPlayerPropBet = bet_type.startsWith("player");
  let logoUrl = null;

  let cardStyle = {};
  if (is_green) {
    cardStyle = { backgroundColor: 'rgba(0, 255, 0, 0.2)' }; 
  } else if (expandedStyle) {
    cardStyle = { backgroundColor: '#2a3947' }; 
  } 

  if (!isPlayerPropBet) {
    logoUrl = isTotalBet
      ? generateLeagueLogoUrl(league)
      : generateTeamLogoUrl(name, league);
  } else {
    logoUrl = generateLeagueLogoUrl(league);
  }

  let displayBetType = bet_type;
  if (displayBetType.includes('Total')) {
    displayBetType = bet_type
  } else if (displayBetType.includes('player')) {
    const wordArray = displayBetType.split('_');
    if (wordArray.length === 2) {
      displayBetType = wordArray[1];
    } 
    else {
      displayBetType = ''
      for (let i = 1; i < wordArray.length; i++) {
        displayBetType += `${wordArray[i]}  `
      }
    }
  } else if (displayBetType === "ML") {
    displayBetType = "Moneyline";
  } 

  const footerText = game_status === 'Not Started' ?  game_time : game_status;

  return (
    <div className={styles.currentAlertCard} style={cardStyle} >
      <div className={styles.alertInfo}>
        <div className={styles.logoAndName}>
          {logoUrl && (
            <img
              src={logoUrl}
              alt={`${name} logo`}
              className={styles.logoImage}
            />
          )}
          <div className={styles.indivName}>{name} <span className={styles.gameStatusOrTime}>{footerText}</span></div>
        </div>
      </div>
      <div>{league}</div>
      <div className={styles.bet_type}>{displayBetType}</div>
      <div className={styles.user_odds}>{user_odds}</div>
      <div className={styles.currentOdds}>{current_odds}</div>
      {!expandedStyle && 
        <button className={styles.button_8} onClick={() => onDelete(alertID)}>Delete Alert</button>
      }
    </div>
  );
};

export default CurrentAlertCard;
