export const prepareAlertData = (alert, currentTime=null) => {
  let alertData = [];
  if (!currentTime) {
        alertData = [
          String(alert.gameID),
          String(alert.league),
          String(alert.teamName),
          String(alert.betType),
          String(alert.oddsThreshold),
          String(alert.h_a),
          String(alert.category)
        ];
      }
    else {
      alertData = [
        String(currentTime),
        String(alert.gameID),
        String(alert.league),
        String(alert.teamName),
        String(alert.betType),
        String(alert.oddsThreshold),
        String(alert.h_a),
        String(alert.category)
      ];
    }

  return alertData;
};

export const updateAlert = (alert, socketData) => {

  const newAlert = { ...alert };
  let bet_star = null;


  if (alert.bet_type.includes("player") || alert.bet_type.includes("Total")) {
    const parts = alert.bet_type.split(" ");
    bet_star = parts[parts.length - 1]; 
  }
  if (alert.bet_type.includes("player")) {
    
    const propsKey = `${alert.league.toLowerCase()}PropsObjects`;
    const propsData = socketData[propsKey];
    if (propsData && newAlert.game_id in propsData) {
      const gameProps = propsData[newAlert.game_id];
      const betTypeKey = newAlert.bet_type.split(" ")[0];
      bet_star = newAlert.bet_type.split(" ")[2];
      if (gameProps && betTypeKey in gameProps) {
        const playerProps = gameProps[betTypeKey];
        if (playerProps && newAlert.name in playerProps) {
          newAlert.current_odds = playerProps[newAlert.name]["point"];
        } else {
          newAlert.current_odds = "-";
        }
      } else {
        newAlert.current_odds = "-";
      }
    }
  } else {
    const liveOddsKey = `${alert.league.toLowerCase()}GameObjects`;
    if (Object.keys(socketData[liveOddsKey]).length > 0) {
      if (newAlert.game_id in socketData[liveOddsKey]) {
        const alertOdds = socketData[liveOddsKey][newAlert.game_id];
        if (alertOdds && Object.keys(alertOdds).length > 0) {
          const home_away = newAlert.h_a === "h" ? "home" : "away";
          const bet =
            newAlert.bet_type === "Spread"
              ? "spread"
              : newAlert.bet_type === "ML"
              ? "ml"
              : "game_total";
          if (bet === 'game_total') {
            bet_star = newAlert.bet_type.split(" ")[2]
          }
          const x =
            bet === "game_total"
              ? "game_total_live"
              : `${home_away}_${bet}_live`;
          const current_odds = alertOdds[x];
          newAlert.current_odds = current_odds;
        }
      } else {
        newAlert.current_odds = "-";
      } //
    }
  }
const parseOdds = (odds) => isNaN(parseFloat(odds)) ? NaN : parseFloat(odds);
const userOdds = parseOdds(newAlert.user_odds);
const currentOdds = parseOdds(newAlert.current_odds);

if (!isNaN(userOdds) && !isNaN(currentOdds)) {
  if (bet_star === 'over') {
    newAlert.is_green = currentOdds <= userOdds;
  } else if (bet_star === 'under') {
    newAlert.is_green = currentOdds >= userOdds;
  } else {
    newAlert.is_green = userOdds <= currentOdds;
  }
} else {
  newAlert.is_green = false; 
}
return newAlert;


}