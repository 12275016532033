import React, { useState, useContext } from "react";
import styles from "../CSS/LoginCard.module.css"; 
import { AuthContext } from "../../contexts/AuthContext";

const LoginCard = ({ onHide }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isSignup, setIsSignup] = useState(false); 
  const { login, signup } = useContext(AuthContext);

  const handleAuth = async (e) => {
    e.preventDefault();
    if (isSignup) {
      await signup(username, password);
      setIsSignup(false);
    } else {
      await login(username, password, onHide);
    }
  };
  return (
    <div className={styles.loginContainer}>
      <button onClick={onHide} className={styles.exitButton}>
        &times;
      </button>
      <h3 className={styles.loginHeader}>Login</h3>
      <form onSubmit={handleAuth} className={styles.loginForm}>
        <div className={styles.formSection}>
          <label htmlFor="username" className={styles.formLabel}>
            Username:
          </label>
          <input
            id="username"
            className={styles.formInput}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className={styles.formSection}>
          <label htmlFor="password" className={styles.formLabel}>
            Password:
          </label>
          <input
            type="password"
            id="password"
            className={styles.formInput}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className={styles.loginButtonDiv}>
          <button type="submit" className={styles.loginButton}>
            {isSignup ? "Sign Up" : "Login"}
          </button>
          <div className={styles.toggleButtonDiv}>
            <button
              type="button"
              onClick={() => setIsSignup(!isSignup)}
              className={styles.toggleButton}
            >
              {isSignup
                ? "Already have an account? Login"
                : "Need an account? Sign Up"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginCard;
