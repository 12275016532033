import { jwtDecode } from "jwt-decode";

export const getToken = () => {
  return localStorage.getItem("token");
};

export const removeToken = () => {
  localStorage.removeItem("token");
};

export const decodeTokenA = (token) => {
  try {
    return jwtDecode(token);
  } catch (error) {
    console.error("Error decoding token");
    return null;
  }
};
