import React from "react";
import styles from "../CSS/IndivGameHeader.module.css";
import { Link } from "react-router-dom";
import classNames from "classnames";

const IndivGameHeader = ({ league, gameid, currentPropName, markets }) => {
  const getLinkClass = (propName) => {
    return classNames({
      [styles.link]: true,
      [styles.activeLink]: currentPropName === propName,
    });
  };

  return (
    <div className={styles.headerContainer}>
      <Link
        to={`/game?league=${league}&gameid=${gameid}&prop_name=game_bets`}
        className={getLinkClass("game_bets")}
      >
        <div className={styles.propLink}>
          <span>Game Bets</span>
        </div>
      </Link>

      {markets && markets.map((market) => (
        <Link
          key={market}
          to={`/game?league=${league}&gameid=${gameid}&prop_name=${market}`}
          className={getLinkClass(market)}
        >
          <div className={styles.propLink}>
            <span>{market.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default IndivGameHeader;
