import React, { useState } from "react";
import NavBar from "../components/misc/NavBar.js";
import CurrentAlertsDisplay from "../components/alerts/CurrentAlertsDisplay.js";
import AlertsFilter from "../components/alerts/AlertsFilter.js";
import Header from "../components/misc/Header.js";
import styles from "./CSS/CurrentAlerts.module.css";

const CurrentAlerts = () => {
  const [filterCriteria, setFilteraCriteria] = useState({});

  const handleFilterChange = (criteriaName, value) => {
    setFilteraCriteria((prevCriteria) => ({
      ...prevCriteria,
      [criteriaName]: value,
    }));
  };

  const handleResetFilters = () => {
    setFilteraCriteria({});
  };

  return (
    <div>
      <div className={styles.headerContainer}>
        <Header league="Current Alerts" />
      </div>
      <div className={styles.pageLayout}>
        <div className={styles.navBarContainer}>
          <NavBar />
        </div>
        <div className={styles.mainContent}>
          <div className={styles.alertsAndFilter}>
            <div className={styles.alertsContainer}>
              <CurrentAlertsDisplay filterCriteria={filterCriteria} />
            </div>
            <div className={styles.alertsFilterContainer}>
              <AlertsFilter
                onFilterChange={handleFilterChange}
                onResetFilters={handleResetFilters}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentAlerts;
