export const fetchGames = async (league) => {
  const sportsbook = localStorage.getItem("sportsbook") || "fanduel";

  try {
    const url = `https://livebetalert-297138c52c76.herokuapp.com/data/fetch-current-data/${league}?sportsbook=${encodeURIComponent(
      sportsbook
    )}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error fetching ${league} games:`, error);
    return [];
  }
};

export const fetchGame = async (league, gameid) => {
  const sportsbook = localStorage.getItem("sportsbook") || "fanduel";

  try {
    const url = `https://livebetalert-297138c52c76.herokuapp.com/data/fetch-game-data?league=${league}&gameid=${gameid}&sportsbook=${encodeURIComponent(
      sportsbook
    )}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error fetching ${league} games:`, error);
    return [];
  }
};

export const fetchMarkets = async (league) => {
  try {
    const url = `https://livebetalert-297138c52c76.herokuapp.com/props/fetch-markets/${league}`;

    const response = await fetch(url);

    if (response.status === 404) {
      return {
        noMarketsAvailable: true,
        message: "No markets available for this league.",
      };
    } else if (!response.ok) {
      throw new Error(`HTTP Error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching markets: ", error);
    return {
      error: true,
      message: "An error occurred while fetching markets.",
    };
  }
};

export const fetchPropBets = async (league, gameid, propName) => {
  const sportsbook = localStorage.getItem("sportsbook") || "fanduel";

  try {
    const url = `https://livebetalert-297138c52c76.herokuapp.com/props/fetch-props/${league}?sportsbook=${encodeURIComponent(
      sportsbook
    )}&gameid=${gameid}&prop_name=${propName}`;

    const response = await fetch(url);

    if (response.status === 404) {
      return {
        noPropsAvailable: true,
        message: "No prop bets available for this game.",
      }
    }
    
    if (!response.ok) {
      throw new Error(`HTTP Error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching prop bets: ", error);
    return {
      error: true,
      message: "An error occurred while fetching prop bets.",
    };
  }
};

export const createAlert = async (alertData, token) => {
  try {
    const response = await fetch("https://livebetalert-297138c52c76.herokuapp.com/alert/create-alert", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(alertData),
    });

    const responseData = await response.json();
    return { ok: response.ok, data: responseData };
  } catch (error) {
    console.error("Error during alert creation:", error);
    return { ok: false, error };
  }
};

export const fetchAlerts = async (token) => {
  const sportsbook = localStorage.getItem("sportsbook") || "fanduel";

  if (!token) {
    console.log("No token found");
    return null;
  }

  try {
    const response = await fetch(
      `https://livebetalert-297138c52c76.herokuapp.com/alert/fetch-alerts?sportsbook=${encodeURIComponent(
        sportsbook
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error during fetch:", error);
    return null;
  }
};

export const createPlayerPropAlert = async (alertData, token) => {
  try {
    const url = `https://livebetalert-297138c52c76.herokuapp.com/alert/create-prop-alert`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, 
      },
      body: JSON.stringify(alertData),
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(`HTTP Error! status: ${response.status}`);
    }

    return { ok: true, data: data };
  } catch (error) {
    console.error("Error posting player prop alert: ", error);
    return { error: error.message };
  }
};

export const deleteAlert = async (alertID, token) => {
  try {
    const url = `https://livebetalert-297138c52c76.herokuapp.com/alert/delete-alert/${alertID}`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`, 
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP Error! status: ${response.status}`);
    }

    return { ok: true, message: "Alert deleted successfully" };
  } catch (error) {
    console.error("Error deleting alert: ", error);
    return { error: error.message };
  }
};

export const updateSportsbook = async (sportsbook, token) => {
  if (!token) {
    console.log("No token found");
    return null;
  }
  try {
    const response = await fetch(
      "https://livebetalert-297138c52c76.herokuapp.com/user/update-sportsbook",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ sportsbook }),
      }
    );

    const data = await response.json();

    if (response.ok) {
      return data;
    } else {
      throw new Error(data.error || "Error updating sportsbook");
    }
  } catch (error) {
    console.error("Error in updateSportsbook:", error);
    throw error; 
  }
};

export const changeParlayName = async (oldParlayId, newParlayName) => {
  try {
    const token = localStorage.getItem('token'); 
    const response = await fetch("https://livebetalert-297138c52c76.herokuapp.com/alert/change-parlay-name", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`, 
      },
      body: JSON.stringify({
        old_parlay_id: oldParlayId,
        new_name: newParlayName,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to change parlay name');
    }

    const data = await response.json();
    return { ok: true, data };
  } catch (error) {
    console.error("Error changing parlay name:", error);
    return { ok: false, error: error.message };
  }
};


export const deleteParlay = async (parlayId) => {
  try {
    const token = localStorage.getItem('token'); 
    const response = await fetch("https://livebetalert-297138c52c76.herokuapp.com/alert/delete-parlay", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`, 
      },
      body: JSON.stringify({
        parlay_id: parlayId,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to delete parlay');
    }

    const data = await response.json();
    return { ok: true, data };
  } catch (error) {
    console.error("Error deleting parlay:", error);
    return { ok: false, error: error.message };
  }
};