import React, { useEffect, useState, useMemo } from "react";
import isEqual from "lodash/isEqual";
import { getToken } from "../../utils/auth";
import { useSocket } from "../../contexts/SocketContext";
import { updateAlert } from "../../utils/alertService";
import { fetchAlerts, deleteAlert, changeParlayName, deleteParlay } from "../../api";
import CurrentAlertCard from "./CurrentAlertCard";
import ParlayCard from "./ParlayCard";
import styles from "../CSS/CurrentAlertsDisplay.module.css";

const CurrentAlertsDisplay = ({ filterCriteria }) => {
  const [alerts, setAlerts] = useState([]);
  const [parlays, setParlays] = useState({})
  const socketData = useSocket();

   const updatedAlerts = useMemo(() => {
    return alerts.map(alert => updateAlert(alert, socketData));
    // eslint-disable-next-line
  }, [socketData]);

    
    const updatedParlays = useMemo(() => {
      const newParlays = { ...parlays };
      Object.entries(newParlays).forEach(([parlayId, parlayAlerts]) => {
        newParlays[parlayId] = parlayAlerts.map(alert => updateAlert(alert, socketData));
      });
      return newParlays;
      // eslint-disable-next-line
    }, [socketData]);

  useEffect(() => {
    if (!isEqual(alerts, updatedAlerts)) {
      setAlerts(updatedAlerts);
    }
    // eslint-disable-next-line
  }, [updatedAlerts]); 

  useEffect(() => {
    if (!isEqual(parlays, updatedParlays)) {
      setParlays(updatedParlays);
    }
    // eslint-disable-next-line
  }, [updatedParlays]); // Depends only on updatedParlays
  

  useEffect(() => {
    const performFetch = async () => {
      const token = getToken();
      if (!token) {
        console.log("No token found");
        return;
      }
      const alertsData = await fetchAlerts(token);

      if (!alertsData || !alertsData.alerts || alertsData.alerts.length === 0) {
        return
      } else {
        const [potentialParlays, ...individualAlerts] = alertsData.alerts;

        setParlays(potentialParlays);
        setAlerts(individualAlerts);
      }
    };
    performFetch();
  }, []);

  const handleParlayNameChange = async (parlayId, newName) => {
    const token = getToken();
    if (!token) {
      console.log("No token found");
      return;
    }
  
    const result = await changeParlayName(parlayId, newName, token);
  
    if (result && result.ok) {
      const updatedParlays = { ...parlays };
      updatedParlays[newName] = updatedParlays[parlayId];
      delete updatedParlays[parlayId];
      setParlays(updatedParlays);
      alert("Parlay name changed successfully!");
    } else {
      alert("Failed to change parlay name.");
    }
  };
  

  const handleDeleteAlert = (alertID) => {
    setAlerts((prevAlerts) =>
      prevAlerts.filter((alert) => alert.alert_id !== alertID)
    );
    const token = getToken();
    deleteAlert(alertID, token);
  };

  const handleDeleteParlay = async (parlayId) => {
    const result = await deleteParlay(parlayId);
    if (result.ok) {
      const updatedParlays = { ...parlays };
      delete updatedParlays[parlayId];
      setParlays(updatedParlays);
      alert("Parlay deleted successfully!");
    } else {
      alert("Failed to delete parlay.");
    }
  };

  const filteredAlerts = useMemo(() => {
    return alerts.filter(alert => {
      const filterByLeague = filterCriteria.league ? alert.league === filterCriteria.league : true;
      const filterByBetType = filterCriteria.bet_type ? alert.bet_type.includes(filterCriteria.bet_type) : true;
      const filterBySearch = filterCriteria.search ? alert.name.toLowerCase().includes(filterCriteria.search.toLowerCase()) : true;
      const filterByIsGreen = filterCriteria.is_green ? alert.is_green === filterCriteria.is_green : true;
      
      return filterByLeague && filterByBetType && filterBySearch && filterByIsGreen;
    });
  }, [alerts, filterCriteria]);

  return (
    <div className={styles.alertsContainer}>
      {alerts.length === 0 && Object.keys(parlays).length === 0 ? (
        <div className={styles.noAlertsMessage}>You have not created any alerts.</div>
      ) : (
        <>
          <div className={styles.displayHeader}>
            <span>Team/Player</span>
            <span>League</span>
            <span>Bet Type</span>
            <span>User Odds</span>
            <span>Current Odds</span>
            <span>Update/Delete</span>
          </div>
          {/* Render Filtered Individual Alerts */}
          {filteredAlerts.map(alert => (
            <CurrentAlertCard
              key={alert.alert_id}
              alertID={alert.alert_id}
              gameID={alert.game_id}
              name={alert.name}
              league={alert.league}
              bet_type={alert.bet_type}
              user_odds={alert.user_odds}
              current_odds={alert.current_odds}
              h_a={alert.h_a}
              is_green={alert.is_green}
              onDelete={handleDeleteAlert}
              game_status={alert.game_status}
              game_time={alert.game_time}
            />
          ))}
          {/* Render Parlays */}
          {Object.entries(parlays).map(([parlayId, parlayAlerts], index) => {
          return (
            <ParlayCard
              key={parlayId}
              parlayAlerts={parlayAlerts}
              parlayName={parlayId}
              parlayId={parlayId}
              onNameChange={handleParlayNameChange}
              onDeleteParlay={handleDeleteParlay}
            />
          );
        })}
          
        </>
      )}
    </div>
  );
};

export default CurrentAlertsDisplay;