import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { SocketProvider } from "./contexts/SocketContext";
import { AlertSlipProvider } from "./contexts/AlertSlipContext";
import "./styles/global.css";
import WelcomePage from "./pages/WelcomePage2";
import NbaPage from "./pages/NbaPage";
import NcaaMbPage from "./pages/NcaaMbPage";
import MlbPage from "./pages/MlbPage";
import IndivGamePage from "./pages/IndivGamePage";
import CurrentAlerts from "./pages/CurrentAlerts";

function App() {
  return (
    <Router>
      <AuthProvider>
        <SocketProvider>
          <AlertSlipProvider>
            <div className="App">
              <Routes>
                <Route exact path="/" element={<WelcomePage />} />
                <Route path="/nba" element={<NbaPage />} />
                <Route path="/ncaa-mb" element={<NcaaMbPage />} />
                <Route path="/mlb" element={<MlbPage />} />
                <Route path="/current-alerts" element={<CurrentAlerts />} />
                <Route path="/game" element={<IndivGamePage />} />
              </Routes>
            </div>
          </AlertSlipProvider>
        </SocketProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
