import React, { useState, useEffect } from "react";
import PlayerPropCard from "./PlayerPropCard";
import { fetchPropBets, fetchGame, fetchMarkets } from "../../api";
import { sortByName } from "../../utils/helpers";
import { useSocket } from "../../contexts/SocketContext";
import GameCard from "../games/GameCard";
import IndivGameHeader from "./IndivGameHeader";
import styles from "../CSS/IndivGameDisplay.module.css";

const IndivGameDisplay = ({ league, gameid, prop_name }) => {
  const [propBets, setPropBets] = useState([]);
  const [gameData, setGameData] = useState({});
  const [markets, setMarkets] = useState([]);
  const socketData = useSocket();

  const propsKey = `${league.toLowerCase()}PropsObjects`;
  const propsData = socketData[propsKey];

  const gameDataKey = `${league.toLowerCase()}GameObjects`;
  const gameDataData = socketData[gameDataKey];

  useEffect(() => {
    const fetchMarketsArray = async () => {
      try {
        const fetchedMarkets = await fetchMarkets(league);
        if (fetchedMarkets && !fetchedMarkets.error) {
          setMarkets(fetchedMarkets.markets);
        } else {
          console.error("Error fetching markets: ", fetchedMarkets.message);
          setMarkets([]);
        }
      } catch (error) {
        console.error("Error in fetching markets: ", error);
      }
    };

    fetchMarketsArray();
  }, [league]);

  useEffect(() => {
    const getGameData = async () => {
      try {
        const data = await fetchGame(league, gameid);
        if (data) {
          if (data.noDataAvailable === true) {
            return;
          } else {
            setGameData(data.data);
          }
        }
      } catch (e) {
        console.error(e);
      }
    };

    getGameData();
  }, [league, gameid]);

  useEffect(() => {
    if (prop_name === "game_bets") {
      return;
    }
    const getPropBets = async () => {
      try {
        const data = await fetchPropBets(league, gameid, prop_name);
        if (data) {
          if (data.noPropsAvailable === true) {
            setPropBets([]);
            return;
          } else {
            if (data) {
              setPropBets(Object.entries(data).sort(sortByName));
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    };

    getPropBets();
  }, [league, gameid, prop_name]);

  useEffect(() => {
    if (propsData && Object.keys(propsData).length > 0) {
      const propsObjects = propsData[gameid][prop_name];
      if (propsObjects) {
        setPropBets(Object.entries(propsObjects).sort(sortByName));
      }
    }
  }, [gameid, prop_name, propsData]);

  useEffect(() => {
    if (gameDataData && Object.keys(gameDataData).length > 0) {
      const gameDataObject = gameDataData[gameid];
      if (gameDataObject) {
        setGameData(gameDataObject);
      }
    }
  }, [gameid, gameDataData]);

  return (
    <div className={styles.propsContainer}>
      <div className={styles.gameData}>
        {gameData ? (
          gameData.game_status === "Not Started" ? (
            <>
              <p>
                {gameData.away_team} @ {gameData.home_team}
              </p>
              <p>Game Start: {gameData.game_time}</p>
              <p>Game Status: {gameData.game_status}</p>
            </>
          ) : (
            <>
              <p>
                {gameData.away_team} @ {gameData.home_team}
              </p>
              <p>Game Status: {gameData.game_status}</p>
              <div className={styles.scoreDiv}>
                <p>
                  {gameData.away_team}: {gameData.away_score}
                </p>
                <p>
                  {gameData.home_team}: {gameData.home_score}
                </p>
              </div>
            </>
          )
        ) : (
          <p>No game data available</p>
        )}
      </div>
      <div className={styles.headersDiv}>
        <IndivGameHeader
          league={league}
          gameid={gameid}
          currentPropName={prop_name}
          markets={markets}
        />
        {prop_name === "game_bets" ? (
          <div className={styles.gameDisplayHeader}>
            <span>Team</span>
            <span>Score</span>
            <span>Pregame Odds</span>
            <span>Live ML</span>
            <span>Live Spread</span>
            <span>Live Total</span>
          </div>
        ) : (
          <div className={styles.displayHeader}>
            <span style={{ display: "flex", justifyContent: "flex-start", marginLeft: "15px" }}>
              Player Name
            </span>
            <span>Pregame Value</span>
            <span style={{ display: "flex", justifyContent: "flex-end", marginRight: "19px" }}>Over</span>
            <span style={{ display: "flex", justifyContent: "flex-start", marginLeft: "10px" }}>Under</span>
          </div>
        )}
      </div>
      {prop_name === "game_bets" ? (
        gameData.id ? (
          <GameCard key={gameData.id} game={gameData} />
        ) : null
      ) : (
        propBets.length > 0 ? (
          propBets.map(([playerName, propDetails]) => (
            <PlayerPropCard
              key={playerName}
              gameID={gameid}
              player={playerName}
              propName={prop_name}
              propValue={propDetails.point}
              overVig={propDetails.Over}
              underVig={propDetails.Under}
              pregameValue={propDetails.pregame_point}
              league={league}
            />
          ))
        ) : (
          <div className={styles.noPropsMessage}>No prop bets available for this market.</div>
        )
      )}
    </div>
  );
};

export default IndivGameDisplay;