import React, { useEffect, useState, useMemo } from "react";
import GameCard from "./GameCard";
import { useSocket } from "../../contexts/SocketContext";
import { fetchGames } from "../../api.js";
import { sortGames } from "../../utils/helpers.js";
import styles from "../CSS/GameDisplay.module.css";

const GamesDisplay = ({ league, filterCriteria }) => {
  const [games, setGames] = useState([]);
  const socketData = useSocket();

  const socketGamesKey = `${league.toLowerCase()}GameObjects`;
  const leagueLiveOdds = socketData[socketGamesKey];

  useEffect(() => {
    const fetchData = async () => {
      const gamesData = await fetchGames(league.toLowerCase());
      if (gamesData[1] !== 401) {
        setGames(sortGames(Object.values(gamesData)));
      }
    };
    fetchData();
  }, [league]);

  useEffect(() => {
    if (leagueLiveOdds && Object.keys(leagueLiveOdds).length > 0) {
      setGames(sortGames(Object.values(leagueLiveOdds)));
    }
  }, [leagueLiveOdds]);

  const filteredGames = useMemo(() => {
    return games.filter(game => {
      const filterBySearch = filterCriteria?.search ? game.home_team.toLowerCase().includes(filterCriteria.search.toLowerCase()) || 
      game.away_team.toLowerCase().includes(filterCriteria.search.toLowerCase()) : true;

      return filterBySearch;
    }).filter((game) => !game.game_completed);
  }, [games, filterCriteria]);

  return (
    <div className={styles.gamesContainer}>
      {filteredGames.length > 0 ? (
        <>
          <div className={styles.displayHeader}>
            <span>Team</span>
            <span>Score</span>
            <span>Pregame Odds</span>
            <span>Live ML</span>
            <span>Live Spread</span>
            <span>Live Total</span>
          </div>
          {filteredGames.map((game) => (
            <GameCard key={game.id} game={game} />
          ))}
        </>
      ) : (
        <div className={styles.noGamesMessage}>No games available.</div>
      )}
    </div>
  );
};

export default GamesDisplay;
