import React from "react";
import { useAlertSlip } from "../../contexts/AlertSlipContext";
import styles from "../CSS/PlayerPropCard.module.css";

const PlayerPropCard = ({
  gameID,
  league,
  player,
  propName,
  propValue,
  overVig,
  underVig,
  pregameValue
}) => {

  const { addAlertSlip } = useAlertSlip();
  const handleAlertClick = (typeInput, currentOdds) => {

    const betType = `${propName} - ${typeInput}`;
    const oddsThreshold = "";
    const teamName = player;
    const h_a = ''

    const alertData = {
      gameID,
      teamName,
      betType,
      league,
      category: "prop",
      oddsThreshold, 
      h_a,
      currentOdds
    };
    addAlertSlip(alertData);
  };

  return (
    <div className={styles.playerPropCard}>
      <div className={styles.playerName}>{player}</div>
      <div className={styles.pregameValue}>{pregameValue}</div>
      <div 
      style={{display: 'flex', justifyContent: 'flex-end', marginRight: '3px'}}
      className={styles.valueDiv}>
      <div
        className={styles.clickableDiv}
        onClick={() => handleAlertClick("over", propValue)}
        
      >
        <div>
          <span style={{fontSize: '12px'}}>O </span> 
          {propValue}</div> 
        <div className={styles.vigValue}>{overVig}</div> 
      </div>
      </div>
      <div 
      style={{display: 'flex', justifyContent: 'flex-start'}}
      className={styles.valueDiv}>
      <div
        className={styles.clickableDiv}
        onClick={() => handleAlertClick("under", propValue)}
      >
        <div>
        <span style={{fontSize: '12px'}}>U </span>  
          {propValue}</div> 
        <div className={styles.vigValue}>{underVig}</div> 
      </div>
    </div>
    </div>
  );
};

export default PlayerPropCard;