import React, { useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import styles from "../CSS/NavBar.module.css";
import { useAuth } from "../../contexts/AuthContext";

const NavBar = ({ onSignInClick }) => {
  const navigate = useNavigate();
  const location = useLocation(); 
  const { isLoggedIn, signOut } = useAuth();
  const [isSportsbookDropdownOpen, setIsSportsbookDropdownOpen] = useState(false);
  const currentSportsbook = localStorage.getItem("sportsbook");

  const sportsbooks = ['fanduel', 'draftkings', 'betmgm', 'williamhill_us', 'betonlineag', 'mybookieag', 'pointsbetus'];

  const getLinkClass = (path) => {
    return location.pathname === path ? styles.activeLink : '';
  };

  const handleSignOut = () => {
    signOut();
    navigate("/");
  };

  const handleSportsbookChange = (newSportsbook) => {
    localStorage.setItem("sportsbook", newSportsbook);
    window.location.reload();
  };

  const handleRestrictedPageAccess = (event, pagePath) => {
    if (!isLoggedIn) {
      event.preventDefault(); // Prevent the default anchor behavior.
      alert("You must sign in");
      onSignInClick();
    } else {
      navigate(pagePath);
    }
  };

  const toggleSportsbookDropdown = () => {
    setIsSportsbookDropdownOpen(!isSportsbookDropdownOpen);
  };

  return (
    <nav className={styles.navbar}>
      <Link to="/" className={getLinkClass("/")}>Home</Link>
      <Link to="/nba" className={getLinkClass("/nba")}>NBA</Link>
      <Link to="/ncaa-mb" className={getLinkClass("/ncaa-mb")}>NCAA MB</Link>
      <Link to="/mlb" className={getLinkClass("/mlb")}>MLB</Link>
      <a href="/current-alerts" className={getLinkClass("/current-alerts")} onClick={(event) => handleRestrictedPageAccess(event, "/current-alerts")}>
        Current Alerts
      </a>
      {isLoggedIn ? (
        <a href="/" onClick={handleSignOut}>
          Sign Out
        </a>
      ) : (
        <a href="/" onClick={onSignInClick}>
          Sign In
        </a>
      )}
      <button onClick={toggleSportsbookDropdown}>Change Sportsbook</button>
      {isSportsbookDropdownOpen && (
        <div className={styles.sportsbookDropdown}>
          <ul className={styles.sportsbookDropdownMenu}>
            {sportsbooks.map((sb, index) => (
              <li
                key={index}
                onClick={() => handleSportsbookChange(sb)}
                className={sb === currentSportsbook ? styles.activeSportsbook : ""}
              >
                {sb}
              </li>
            ))}
          </ul>
        </div>
      )}
    </nav>
  );
};


export default NavBar;