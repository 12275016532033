import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { decodeTokenA } from "../utils/auth";

export const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthStatus = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setIsLoggedIn(false);
        return;
      }

      try {
        const response = await fetch("https://livebetalert-297138c52c76.herokuapp.com/user/profile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          setIsLoggedIn(true);
        } else {
          localStorage.removeItem("token");
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error("Error during token validation:", error);
        localStorage.removeItem("token");
        setIsLoggedIn(false);
      }
    };

    checkAuthStatus();
  }, []);

  const login = async (username, password, onSuccess) => {
    try {
      const response = await fetch("https://livebetalert-297138c52c76.herokuapp.com/user/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("token", data.token);
        setIsLoggedIn(true);
        localStorage.setItem("sportsbook", decodeTokenA(data.token).sportsbook);
        if (onSuccess) onSuccess();
      } else {
        console.error("Login failed:", data.error);
        window.alert(
          "Invalid login, make sure username and password are correct."
        );
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const signup = async (username, password) => {
    try {
      const response = await fetch("https://livebetalert-297138c52c76.herokuapp.com/user/register", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();
      if (response.ok) {
        console.log("Signup successful:", data);
      } else {
        console.error("Signup failed:", data.error);
        window.alert("Username already exists");
      }
    } catch (error) {
      console.error("Error during signup:", error);
    }
  };

  const signOut = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
    navigate("/");
  };

  const authContextValue = {
    isLoggedIn,
    login,
    signup,
    signOut,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
