import React from 'react';
import styles from "../CSS/GamesSearch.module.css";

const GamesSearch = ({ onFilterChange }) => {

  const handleFilterChange = (e) => {
    onFilterChange(e.target.name, e.target.value);
  };

  return (
    <div className={styles.gameSearchContainer}>
        <div className={styles.searchForm}>
        <span className={styles.searchText}>Find a game:</span> 
        <form>
              <input
                type="text"
                name="search"
                onChange={handleFilterChange}
                placeholder="Search by name.."
                style={{
                  width: 150 + "px",
                }}
              />
            </form>
        </div>
    </div>
  );
};

export default GamesSearch;