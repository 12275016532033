import React, { useState } from "react";
import AlertSlipItem from "./AlertSlipItem";
import { getToken } from "../../utils/auth";
import { createAlert } from "../../api";
import { prepareAlertData } from "../../utils/alertService";
import { useAlertSlip } from "../../contexts/AlertSlipContext";
import { useAuth } from "../../contexts/AuthContext";
import styles from "../CSS/AlertSlip.module.css";

const AlertSlip = () => {
  const [isParlay, setIsParlay] = useState(false);
  const { isLoggedIn } = useAuth();
  const { clearAlertSlip, alertSlipItems, removeAlertSlip } = useAlertSlip();

  
  const handleSubmitAlerts = async (event) => {
  
    const token = getToken();
    event.preventDefault();
    
    if (!isLoggedIn) {
      alert("You must sign in");
      return
    }

    if (isParlay) {
      if (Object.keys(alertSlipItems).length < 2) {
        alert('Not enough alerts for a parlay');
        return; 
      }
    const currentTime = new Date().getTime();
    const parlaySubmissionData = {[currentTime]: Object.values(alertSlipItems).map(alert => 
      prepareAlertData(alert)
    )};
    const result = await createAlert(parlaySubmissionData, token);
    if (result && result.ok) {
      alert("Parlay submitted successfully");
      clearAlertSlip();
      setIsParlay(false);
    } else {
      alert("Failed to submit parlay");
    }
  }
  else {
    let hasErrors = false;
  
    for (let alert of Object.values(alertSlipItems)) {
      try {
        const alertSubmissionData = prepareAlertData(alert, alert.id); 
        const result = await createAlert(alertSubmissionData, token);
        if (result.ok) {
          removeAlertSlip(alert.id);
        }
        if (!result.ok) {
          console.error("Failed to submit alert", alert);
          hasErrors = true;
        }
      } catch (error) {
        console.error("Error submitting alert", alert, error);
        hasErrors = true;
      }
    }
  
    if (!hasErrors) {
      alert("All alerts submitted successfully");
      clearAlertSlip(); 
    } else {
      alert("Some alerts failed to submit");
    }
  }
  
  }
   
  return (
    <div className={styles.alertSlipContainer}>
      <div className={styles.alertSlipHeader}>AlertSlip</div>
      <div className={styles.alertItemsContainer}>
      {
    Object.keys(alertSlipItems).length === 0 ? (
      <div className={styles.noAlertsMessage}>
        No alerts to display.
      </div>
    ) : (
      Object.values(alertSlipItems).map((alert) => (
        <AlertSlipItem key={alert.id} alert={alert} />
      ))
    )
  }
      </div>
        <div className={styles.parlayBanner}>
          <label className={styles.parlayCheckboxLabel}>
            <input 
            type="checkbox" 
            className={styles.parlayCheckbox}
            checked={isParlay} 
            onChange={(e) => setIsParlay(e.target.checked)} 
            />
            Parlay
          </label>
          <div className={styles.buttonDiv}>
          <button 
          className={styles.parlaySubmitButton}
          onClick={handleSubmitAlerts}
          >Submit</button>
          <button 
          className={styles.clearSlipButton}
          onClick={clearAlertSlip}
          >Clear Alerts</button>
          </div>
        </div>
      
    </div>
  );
};

export default AlertSlip;