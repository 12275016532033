import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NavBar from "../components/misc/NavBar";
import Login from "../components/misc/LoginCard";
import { useAuth } from "../contexts/AuthContext";
import styles from "./CSS/WelcomePage.module.css";
import lba_logo from "../images/Livebetalert_logo.png";
import partOneImage from "../images/welcomePartOne.png";
import partOneAddAlert from "../images/partOneAddAlert.mp4";
import currentAlertsNote from "../images/currentAlertsNote.png";
import propsVideo from "../images/propsVideo.mp4";
import parlaysVideo from "../images/parlaysVideo.mp4";
import currentAlertsImage from "../images/partOneCurrentAlerts.png";
import partTwoCurrentAlerts from "../images/partTwoCurrentAlerts.png";

const WelcomePage = () => {
  const { isLoggedIn } = useAuth();
  const [showLoginCard, setShowLoginCard] = useState(!isLoggedIn);
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(error => console.log("Error playing the video", error));
    }
  }, []);

  const toggleLoginCard = () => {
    setShowLoginCard(false);
  };

  const slides = [
    {
      content: (
        <>
          <div className={styles.partOne}>
          <h3>Overview</h3>
            <p>Let's say that the LA Lakers and Milwaukee Bucks are playing tonight. Here's what this looks like on our NBA page:</p>
            <img src={partOneImage} alt="Orlando Magic vs Charlotte Hornets" />
          </div>
        </>
      ),
    },
    {
      content: (
        <>
          <div className={styles.partOne}>
            <h3>Overview</h3>
            <p>You want to bet on the Bucks if their live spread drops to -2.5, so you create 
                an alert.
            </p>
            <video ref={videoRef} autoPlay loop muted playsInline>
              <source src={partOneAddAlert} type="video/mp4"></source>
            </video>
          </div>
        </>
      )
    },
    {
        content: (
          <>
          <div className={styles.partOne}>
          <h3>Overview</h3>
            <p>You can see all your alerts in the Current Alerts page. This page makes it possible 
              to monitor the live odds of all your alerts. When the current odds are equal to or better than the user odds,
                we call this 'in the green'. We've created an alert that is already 'in the green' for display.
            </p>
            <img src={currentAlertsImage} alt="Current Alerts Page" />
            </div>
          </>
        )
      },
      {
        content: (
          <>
          <div className={styles.partOne}>
          <h3>Current Alerts Note </h3>
            <p>
              The 'Current Odds' column (and all of the odds data on the site) will update with fresh odds as soon as they come in. While a game is live, livebetAlert is set up to 
              get fresh data every 45 seconds for game odds and 90 seconds for props. Unfortunately, 
              this means that our odds will not be perfect. We recommend setting odds in a way that works for you. For example, 
              if you really like the Bucks at -2.5, set the alert odds at -3.5 or 
              -4.5. That way, when you see the alert is in the green, you can get to your sportsbook's site and be ready 
              once their odds drop to -2.5. 
            </p>
            <img src={currentAlertsNote} alt=""/>

            </div>
          </>
        )
      },
      {
        content: (
          <>
          <div className={styles.partOne}>
          <h3>Overview</h3>
            <p>If all you cared about was the Bucks at -2.5, livebetAlert wouldnt help much. However, what if you wanted to bet certain NBA teams if 
              their spread increased by 10 points? Or what if you wanted to bet the under on any NBA game who's live total increased by 
              15 points? livebetAlert makes implementing these strategies, and monitoring them over a number of sportsbooks, possible.
            </p>
            <img src={partTwoCurrentAlerts} alt="Current Alerts Page" />
            </div>
          </>
        )
      },
      {
        content: (
          <>
          <div className={styles.partOne}>
          <h3>Prop Bets </h3>
            <p>
              livebetAlert also allows you to create alerts for prop bets. Its the same process as spreads, moneylines and game totals. 
              Simply select the prop bet and set your line, then monitor on the Current Alerts page.
            </p>
            <video ref={videoRef} autoPlay loop muted playsInline>
              <source src={propsVideo} type="video/mp4"></source>
            </video>
            </div>
          </>
        )
      },
      {
        content: (
          <>
          <div className={styles.partOne}>
          <h3>Parlays </h3>
            <p>
              You can also create parlay alerts. Select all the bets in the parlay, set your odds/lines, click the parlay button
              in the AlertSlip and submit. You can even rename the parlays in the Current Alerts page to better keep track of them all. 
            </p>
            <video ref={videoRef} autoPlay loop muted playsInline>
              <source src={parlaysVideo} type="video/mp4"></source>
            </video>
            </div>
          </>
        )
      },
      {
        content: (
          <>
          <div className={styles.partOne}>
          <h3>Getting Started </h3>
            <p>
              To get started, create an account and start creating alerts! For now, you dont even need an email to sign up. 
              Simply create a username, a password and get started. If you forget your password, just create a new account. 
              This process will be formalized shortly, but for now, this is how it is.
            </p>
            </div>
          </>
        )
      },
  ];

  return (
    <div>
      <div className={styles.headerContainer}>
      <div className={styles.header}>
      <div className={styles.logo}>
      <img src={lba_logo} className={styles.logoImage} alt=""/>
      </div>
      <div className={styles.text}>
      <span className={styles.livebet}>livebet</span>
      <span className={styles.alert}>Alert</span>
      </div>
    </div>
        </div>
      <div className={styles.pageLayout}>
        <div className={styles.navBarContainer}>
          <NavBar onSignInClick={toggleLoginCard} />
          {showLoginCard && <Login onHide={toggleLoginCard} />}
        </div>
        <div className={styles.mainContent}>
          <div className={styles.intro}>
            <h2>Welcome to livebetAlert!</h2>
            <h3>livebetAlert is a web application that helps sports bettors implement live betting strategies. Here's how it works.</h3>
          </div>
          <div className={styles.carouselDiv}>
            <Carousel infiniteLoop showThumbs={false} showStatus={false}>
              {slides.map((slide, index) => (
                <div key={index}>
                  {slide.content}
                </div>
              ))}
            </Carousel>
          </div>
          <div className={styles.endDiv}>
          <p>For questions or comments on the site, please email livebetalertinfo@gmail.com</p>
          <p>Follow us on twitter @livebetalert_</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
