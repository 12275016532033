import React from "react";
import lba_logo from "../../images/Livebetalert_logo.png";
import styles from "../CSS/Header.module.css"; 

const Header = ({ league }) => {
  return (
    <div className={styles.header}>
      <div className={styles.logo}>
      <img src={lba_logo} className={styles.logoImage} />
      </div>
      <div className={styles.text}>
      {league && <span className={styles.leagueName}>{league}-</span>}
      <span className={styles.livebet}>livebet</span>
      <span className={styles.alert}>Alert</span>
      </div>
      <div className={styles.emptyDiv}></div>
    </div>
  );
};

export default Header;
