import React, { createContext, useState, useContext } from "react";

const AlertSlipContext = createContext();

export const useAlertSlip = () => useContext(AlertSlipContext);

export const AlertSlipProvider = ({ children }) => {
  const [alertSlipItems, setAlertSlipItems] = useState({});

  const addAlertSlip = (newAlertData) => {
    const timestamp = new Date().getTime(); 
    setAlertSlipItems(prevAlerts => ({
      ...prevAlerts, 
      [timestamp]: {...newAlertData, id: timestamp}
    }));
  };

  const updateAlertSlipData = (alertId, updatedData) => {
    setAlertSlipItems(prevAlerts => ({
      ...prevAlerts, 
      [alertId]: {...prevAlerts[alertId], ...updatedData }
    }));
  };

   const removeAlertSlip = (alertId) => {
    setAlertSlipItems(prevAlerts => {
      const updatedAlerts = { ...prevAlerts };
      delete updatedAlerts[alertId];
      return updatedAlerts;
    });
  };

  const clearAlertSlip = () => {
    setAlertSlipItems({}); 
  };

  return (
    <AlertSlipContext.Provider
      value={{ addAlertSlip, removeAlertSlip, clearAlertSlip, updateAlertSlipData, alertSlipItems }}
    >
      {children}
    </AlertSlipContext.Provider>
  );
};