import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import NavBar from "../components/misc/NavBar";
import Header from "../components/misc/Header";
import Login from "../components/misc/LoginCard";
import AlertSlip from "../components/misc/AlertSlip";
import IndivGameDisplay from "../components/indivGame/IndivGameDisplay";
import styles from "./CSS/IndivGamePage.module.css";

const IndivGamePage = () => {
  const [showLoginCard, setShowLoginCard] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const league = queryParams.get("league");
  const gameid = queryParams.get("gameid");
  const prop_name = queryParams.get("prop_name");

  const toggleLoginCard = () => {
    setShowLoginCard((prevState) => !prevState);
  };

  return (
    <div>
      <div className={styles.headerContainer}>
        <Header />
      </div>
      <div className={styles.pageLayout}>
        <div className={styles.navBarContainer}>
          <NavBar onSignInClick={toggleLoginCard} />
          {showLoginCard && <Login onHide={toggleLoginCard} />}
        </div>
        <div className={styles.mainContent}>
          <div className={styles.propsAndAlerts}>
            <div className={styles.propsContainer}>
            <IndivGameDisplay
              league={league}
              gameid={gameid}
              prop_name={prop_name}
            />
            </div>
            <div className={styles.alertSlipContainerProps}>
              <AlertSlip />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndivGamePage;
