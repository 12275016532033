import React, { useState } from "react";
import { useAlertSlip } from "../../contexts/AlertSlipContext";
import styles from "../CSS/AlertSlipItem.module.css";

const AlertSlipItem = ({ alert }) => {
  const [oddsThreshold, setOddsThreshold] = useState("");
  const { updateAlertSlipData, removeAlertSlip } = useAlertSlip();

  const handleThresholdChange = (e) => {
    const newOddsThreshold = e.target.value;
    setOddsThreshold(newOddsThreshold);
    updateAlertSlipData(alert.id, { ...alert, oddsThreshold: newOddsThreshold });
  };

  let displayBetType = alert.betType;
  if (displayBetType.includes('Total')) {
    displayBetType = `Game ${alert.betType}`
  } else if (displayBetType.includes('player')) {
    const wordArray = displayBetType.split('_');
    if (wordArray.length === 2) {
      displayBetType = wordArray[1];
    } 
    else {
      displayBetType = ''
      for (let i = 1; i < wordArray.length; i++) {
        displayBetType += `${wordArray[i]}  `
      }
    }
  } else if (displayBetType === "ML") {
    displayBetType = "Moneyline";
  } 


  const renderAlertContent = () => {
    if (alert.category === "team" && alert.betType.includes("Total")) {
      return (
        <>
          <h3 className={styles.alertTitle}>
            {alert.teamName} - {alert.opponent}
          </h3>
          <p className={styles.gameSetUp}>{displayBetType}</p>
          <p className={styles.gameSetUp}>
            {alert.teamName} vs. {alert.opponent}
          </p>
        
        </>
      );
    }
    else if (alert.category === "team") {
      return (
        <>
          <h3 className={styles.alertTitle}>
            {alert.teamName}
          </h3>
          <p className={styles.alertSetUp}>{displayBetType}</p>
          <p className={styles.gameSetUp}>
            {alert.teamName} vs. {alert.opponent}
          </p>
        </>
      );
    } else if (alert.category === "prop") {
      return (
        <>
          <h3 className={styles.alertTitle}>{alert.teamName}</h3>
          <p className={styles.gameSetUp}>{displayBetType}</p>
          
        </>
      );
    }
  };

  return (
    <div className={styles.alertSlipItem}>
      <div
        className={styles.removeButton}
        onClick={() => removeAlertSlip(alert.id)}
      >
        &times;
      </div>
      {renderAlertContent()}
      <div className={styles.alertDetails}>
        <form className={styles.thresholdForm}>
          <input
            type="number"
            className={styles.thresholdInput}
            value={oddsThreshold}
            onChange={handleThresholdChange}
            placeholder={`${alert.currentOdds}`}
          />
        </form>
      </div>
    </div>
  );
};

export default AlertSlipItem;